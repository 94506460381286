.document {
    .divider {
        margin-top: 15px;
        margin-right: 10px;
        margin-bottom: 5px;
    }

    .content {
        margin-top: 5px;
        overflow-y: scroll;

        .items {
            margin-right: 10px;

            .item {
                border-bottom: 1px solid silver;

                .itemDetails {
                    .itemDetailsToolbar {
                        .itemDetailsToolbarLeft {
                            display: flex;
                            gap: 10px;
                        }
        
                        .itemDetailsToolbarRight {
                            display: flex;
                            justify-content: flex-end;
        
                            input[type='file'] {
                                width: 0px;
                                height: 0px;
                                margin: 0px;
                                padding: 0px;
                                overflow: hidden;
                            }
                        }
                    }

                    .itemDetailsDivider {
                        margin-top: 15px;
                    }

                    .itemDetailsContent {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}