.error {
    background-color: red;
}

.tooltipAuditTable {
    border-collapse: collapse;

    thead {
        tr {
            th {
                padding: 0px;
                margin: 0px;
                background-color: #1976d2;
                color: white;
                height: 30px;
            }
        }
    }

    tbody {
        tr {
            &:hover {
                td {
                    background: rgba(182, 182, 182, 0.15);
                }
            }

            td {
                height: 20px;
            }
        }

        .tooltipAuditTableField {
            width: 150px;
            color: black;
            font-weight: bold;
        }

        .tooltipAuditTableOldValue {
            width: 300px;
            color: rgb(221, 22, 22);
        }

        .tooltipAuditTableNewValue {
            width: 300px;
            color: rgb(33, 148, 33);
        }
    }
}